<template>
    <div class="ml-3 mb-2 rounded main-container">
        <div class="box-header">About</div>
        <div class="box-body">
            <div class="box-content">
                <div class="box-content-body">
                    <img src="../assets/about-quake.jpg" class="float-right m-2"/>
                    <h1>About Quake and QuakeWorld</h1>
                    <p>
                        <b>Quake</b> is a first-person shooter video game and was released by id Software in 1996.
                        It was the first game in the popular Quake series of video games.
                    </p>
                    <p>
                        To improve the quality of online play, id Software released <b>QuakeWorld</b> on December 17, 1996,
                        a build of Quake that featured significantly revamped network code.
                    </p>
                    <p>
                        In December 1999, John Carmack of id Software released the server and client source code of Quake and QuakeWorld
                        under the GNU General Public License as a Christmas present to the world, and this spawned a plethora of 21st
                        century updates to the game. With the source code released, Quake has branced off in several modern clients which
                        each brings various features and improvements.
                    </p>
                    <p>
                        In 2007 Quake was honored at the 59th Annual Technology & Engineering Emmy Awards for advancing
                        the art form of user modifiable games.
                    </p>
                    <p>
                        Many years after its original release, Quake is still regarded by many critics as one of the greatest
                        and most influential games ever made. It is considered even today by many die-hard players to be the
                        best multiplayer game with its fast paced action and fun gameplay.
                    </p>

                    <img src="../assets/about-qwnu.jpg" class="float-right m-2"/>
                    <h1>QuakeWorld.nu</h1>
                    <p>
                        QuakeWorld.nu is a QuakeWorld site that strives to be the centre for the true quaker. We bring you
                        news and articles from all over the world, and have an active forum where our community can debate the
                        issues of the day, discuss the latest matches or just have a good laugh.
                    </p>
                    <p>
                        The site is developed and run entirely by unpaid volunteers. We do this out of interest of this terrific
                        game we all love, and to strenghten the Quake scene.
                    </p>
                    <p>
                        We look forward to seeing new players in QuakeWorld and becoming a part of the community. Our
                        aims are focused on trying to facilitate this process for any newcomer to our welcoming scene. Furthermore,
                        we try to provide a portal that suits the need of the existing community as well.
                    </p>
                    <p>
                        Please visit us on the <a href="https://discord.quake.world">Discord server</a>!
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "about"
}
</script>
